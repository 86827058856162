:root {
  --font-size: 1.2rem;
  --color: black;
  --color-secondary: rgb(255, 255, 255);
  --background-color: rgb(50, 50, 50);
  --background-color-secondary: rgb(60, 60, 60);
}

body main{
  width: 100%;
}

main {
  width: 100%;
}

#light {
  background-color: #FFFFFF;
}

#dark {
  background-color: #0f0f0f;
}

#light footer {
  color: #868e96;
  background-color: #F8F9FA;
}

#dark footer {
  color: rgb(166, 167, 171);
  /* color: #7d8590; */
  background-color: #141517;
  /* background-color: #0D1117; */
}

        /* HOMEPAGE*/
#dark .home-main {
  background-color: #0f0f0f;
}
#dark .campaign-challange-carousel {
  background-color: #0f0f0f;
}
#dark .campaign-challange-carousel-card {
  background-color: #141414;
  color: white;
}
#dark .campaign-card {
  background-color: #141414;
  color: white;
  box-shadow: 0 0 30px -10px orange;
}
#dark .campaign-box-content {
  background-color: #141414;
  color: white;
}
#dark .campaign-card-header-title {
  background-color: #141414;
  color: white;
}
#dark .campaign-card-content {
  background-color: #141414;
  color: white;
}
#dark .campaign-card-content-organization {
  background-color: #221d1d57;
  color: white;
}
#dark .campaign-card-content-organization-sub {
  background-color: #5f4e4e57;
  color: white;
}
#dark .numbers-square-card-item {
  border-right: 1px solid green;
  border-bottom: 1px solid green;
}
#dark .numbers-square-card-item-second {
  border-bottom: 1px solid green;
}
#dark .numbers-square-card-item-third {
  border-right: 1px solid green;
}
#dark .numbers-card {
  background-color: #141414;
  color: white;
  box-shadow: 0 0 30px -10px orange;
}
#dark .see-all-button {
  color: white;
}

        /*  Landing Page  */
#dark .about-us-main .single-sections {
  background-color: #141414;
}
#dark .about-us-main .double-sections {
  background-color: #0f0f0f;
  color: white;
}
#dark .app-features-list {
  color: white;
}
#dark .footer-title {
  color: white;
}
#dark .help {
  color: white;
  background-color: #0F0F0F;
}
#dark .helpTd {
  border-bottom-color: white;
  border-left-color: white;
  border-right-color: white;
  border-top-color: white;
}
/* #dark .footer-news-from-us {
  color: white;
  background: linear-gradient(to right, #1b8a2f 0, #31ac47 100%);
} */
#dark .footer-news-from-us:hover {
  color: white;
}
#dark .accordion-title {
  color: orange;
}
#dark .conditional-support-card {
  background-color: #141414;
  color: white;
  border: 1px solid orange;
}
#dark .accordion {
  background-color: #0f0f0f;
  color: white;
  border-radius: 10px;
}
#dark .accordion:hover {
  background-color: #0f0f0f;
  color: white;
}
#dark .accordion-control {
  background-color: #0f0f0f;
  color: white;
}

        /* NAVBAR */
#dark .navbar {
  background-color: #0f0f0f;
  border-bottom: 1px solid orange;
}   

/* Campaign Detail */ 
#dark .campaign-detail-main {
  background-color: #0f0f0f;
}
#dark .campaign-detail-content-left {
  background-color: #141414;
  color: white;
}
#dark .campaign-donors-grid {
  background-color: #141414;
  color: white;
}
#dark .campaign-transfer-content {
  background-color: #141414;
  color: white;
  box-shadow: 0 0 30px -10px orange;
}
#dark .campaign-donors-three-card-container {
  background-color: #141414;
  color: white;
}

        /* NOTFOUND */
#dark .not-found {
  background-color: #141414;
  color: white;
  box-shadow: 0 0 30px -10px orange;
}        
#dark .poll-card-dark {
  background-color: #141414;
  color: white;
  box-shadow: 0 0 30px -10px orange;
}  

        /* Rss Carousel */
#dark .rss-carousel-section {
  background-color: #141414;
  color: white;
  box-shadow: 0 0 30px -10px orange;
}
#dark .rss-carousel-desc {
  color: white;
}

    /* Recommended Carousel */
#dark .recommended-carousel-title {
  color: white;
}
#dark .recommended-carousel-container {
  background-color: #0f0f0f;
  color: white;
}
#dark .recommended-carousel-campaigns-box {
  background-color: #141414;
  color: white;
}

        /* AddFavoritesCard */
#dark .add-favorites-card-container {
  border-color: white;
  color: white;
}
#dark .favorites-card-left {
  color: white;
}
#dark .standings-button {
  color: white;
}

        /* STANDİNGS */
#dark .standings-container {
  color: white;
} 

          /* FİXTURES */
#dark .fixture-card-container {
  background-color: #141414;
  color: white;
  border-radius: 10px;
  border-bottom: 3px solid orange;
  margin-bottom: 10px;
}    

      /*  Organisations Detail  */ 
#dark .organisations-detail-main {
  background-color: #0f0f0f;
}      
#dark .campaign-transfer-content {
  background-color: #141414;
}      

        /* My Joined Campaigns */ 
#dark .campaigns-user-joined-main {
  background-color: #0f0f0f;
  color: white;
}        
        /* My Certificates */ 
#dark .campaigns-user-certificates-main {
  background-color: #0f0f0f;
  color: white;
}        
#dark .campaigns-user-certificates-desc {
  border-bottom: 2px solid orange;
}   

      /* My Favourite Teams */
#dark .favourite-teams {
  background-color: #141414;
}
#dark .favourite-sport-card {
  background-color: #141414;
  box-shadow: 0 0 30px -10px orange;
  border-radius: 30px;
  color: white;
}
#dark .remove-favorites-card-container {
  border: 1px solid white;
}

      /* Account Settings */ 
#dark .account-main {
  background-color: #0f0f0f;
}      
#dark .account-container {
  background-color: #141414;
  box-shadow: 0 0 30px -10px orange;
  color: white;
}

      /*  LOGin  */
#dark .login-title {
  color: white;
}       
#dark .login-card {
  box-shadow: 0 0 30px -10px orange;
}     

      /* REGİSTER */
#dark .register-card {
  box-shadow: 0 0 30px -10px orange;
}      
#dark .register-span {
  box-shadow: 0 0 30px -10px orange;
  background-color: #141414;
}    

      /* FİLTER SELECT */
#dark .filter-select-label {
  color: white;
}      

      /* Change E-posta */
#dark .text-input-label {
  color: white;
}

      /* ICON */
#dark .icon-dark {
  background-color: white;
  border-radius: 10px;
  padding: 3px;
}   

       /* NewsFromUsDetail */
#dark .news-from-us-detail {
  color: rgb(192, 192, 192);
}       
      /* Breadcrumbs */
#dark .breadcrumbs {
  color: #0F0F0F;
}       

.switch {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch label{
  position: inherit;
}

#light .switch label {
  color: black;
}

#dark .switch label {
  color: white;
  margin-right: 10px;
}